<template>
  <div class="get-content-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listQuarantines">检疫信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <!-- 标题栏 -->
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            基本信息
          </div>
        </el-col>
      </el-row>
      <!-- basic第一行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">产品名称：</span>
            <span class="basic-infor">{{basicInfor.productName}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">产品批次编号：</span>
            <span class="basic-infor">{{basicInfor.batchNum}}</span>
          </div>
        </el-col>
      </el-row>
      <!-- basic第二行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">检测机构：</span>
            <span class="basic-infor">{{basicInfor.aqCompanyName}}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">检疫日期：</span>
            <span class="basic-infor">{{basicInfor.aqDate}}</span>
          </div>
        </el-col>
      </el-row>
      <!-- basic第三行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">是否合格：</span>
            <span class="basic-infor" v-if="basicInfor.aqFlag==1">是</span>
            <span class="basic-infor" v-else>否</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">备注：</span>
            <span class="basic-infor">{{basicInfor.aqRemark}}</span>
          </div>
        </el-col>
      </el-row>

      <!-- basic第四行 -->
      <el-row>
        <el-col :span="12">
          <div class="basic-content">
            <span class="basic-title">检疫报告：</span>
            <div class="jianyi-content basic-last">
            <el-link class="jianyi-title" v-for="item in basicInfor.files" v-bind:key="item.fileUrl" :underline="false" :href="item.fileUrl" target="_blank">
              {{item.originalName}}
            </el-link>
          </div>
          </div>
        </el-col>
      </el-row>

       <!-- 标题栏 -->
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            区块信息
          </div>
        </el-col>
      </el-row>
      <!-- block第一行 -->
      <div class="chain-info">
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">区块号：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.blockNum}}</span>
          <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第二行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">上链日期：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.createTime}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第三行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">所属分组：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.groupName}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第四行 -->
      <el-row>
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">唯一标识：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.resultHash}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      <!-- block第五行 -->
      <el-row class="last-row">
        <el-col :span="4">
          <div class="block-content">
            <span class="block-dian">·</span>
            <span class="block-title">上链地址：</span>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="block-content">
            <span v-if="basicInfor.blockChain" class="block-infor">{{basicInfor.blockChain.chainUrl}}</span>
            <span v-else class="block-infor">~暂无上链信息~</span>
          </div>
        </el-col>
      </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '详情',
      // 表单对象
      basicInfor: {}

    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      this.title = '详情'
      this.get()
    }
  },
  methods: {
    goBack() {
      this.$router.push('/listQuarantines')
    },
    // 查询
    async get() {
      const params = {
        afcBatchAqId: this.id
      }
      const { data } = await this.$http.quarantinesInfor.getQuarantine(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.basicInfor = data.data
      this.basicInfor.aqDate = data.data.aqDate.substr(0, 10)
    }
  }
}
</script>

<style lang="scss">
.get-content-page {
  .warning {
    color: #e6a23c !important;
  }
  .basic-content{
    font-size:14px;
    line-height: 20px;
    padding: 20px 24px 0 24px;
    display: flex;
    flex-direction: row;
    .basic-title{
      white-space: nowrap;
      color:rgb(29, 29, 29);
    }
    .basic-infor{
      color:rgba(102,102,102,1);
      width: 500px;
    }
    .jianyi-content{
      display: flex;
      flex-direction: column;
      align-items: start;
      color: #3e6df5;
    }
    .jianyi-title{
      color: #3e6df5;
    }
  }
  .basic-last{
    margin-bottom: 32px;
  }
  .block-content{
    font-size:14px;
    line-height: 20px;
    padding: 14px 24px 14px 24px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    .block-dian{
      font-size: 14px;
      color: #3E6DF5;
      font-weight: 900;
      margin-right: 10px;
    }
    .block-title{
      color:rgb(29, 29, 29);
    }
    .block-infor{
      color:#666666;
    }
  }

  .last-row{
    margin-bottom: 24px;
  }

  //下载文件样式
  .download-title{
    color:#3e6df5;
  }
  .download-title:hover{
    color:#668bfa;
    text-decoration: underline;
  }

.chain-info{
  .el-row{
    border-bottom: 1px solid #E8E8E8;
  }
  .block-content {
    border:0;
    word-break: break-all;
  }
}
}
</style>
